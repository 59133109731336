Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = {
  themeForegroundPrimaryDefault: "#FFFFFF",
  themeForegroundPrimaryFocus: "#7CCEE9",
  themeForegroundPrimaryError: "#FF7494",
  themeForegroundPrimaryDisabled: "#697576",
  themeForegroundSecondaryDefault: "#BBC0C1",
  themeForegroundSecondaryDisabled: "#485050",
  themeForegroundTertiaryDefault: "#BBC0C1",
  themeForegroundTertiaryHover: "#E7E9E9",
  themeForegroundTertiaryPressed: "#F0F1F2",
  themeForegroundTertiaryDisabled: "#5B5F5F",
  themeForegroundInvertedDefault: "#232F3E",
  themeForegroundAccentDefault: "#47C2EC",
  themeForegroundAccentHover: "#7CCEE9",
  themeForegroundAccentPressed: "#B9E8F7",
  themeForegroundAccentDisabled: "#204B5A",
  themeBorderPrimaryDefault: "#E7E9E9",
  themeBorderPrimaryDisabled: "#485050",
  themeBorderPrimaryFocus: "#077398",
  themeBorderPrimaryError: "#FF7494",
  themeBorderPrimaryWarning: "#FEF2B8",
  themeBorderSecondaryDefault: "#697576",
  themeBorderSecondaryFocus: "#00A8E1",
  themeBorderTertiaryDefault: "#485050",
  themeBorderAlternateDefault: "#697576",
  themeBorderAccentDefault: "#00A8E1",
  themeBorderAccentHover: "#47C2EC",
  themeBorderAccentPressed: "#7CCEE9",
  themeBorderAccentDisabled: "#24586A",
  themeBorderAccentPreselected: "#055D7B",
  themeBorderAlternateAccentDefault: "#00A8E1",
  themeBackgroundPrimaryDefault: "#0B0C0C",
  themeBackgroundPrimaryDisabled: "#252829",
  themeBackgroundSecondaryDefault: "#252829",
  themeBackgroundSecondaryError: "#AD0A30",
  themeBackgroundSecondaryWarning: "#FAD000",
  themeBackgroundTertiaryDefault: "#485050",
  themeBackgroundTertiaryHover: "#697576",
  themeBackgroundTertiaryPressed: "#949C9C",
  themeBackgroundTertiaryDisabled: "#323838",
  themeBackgroundAlternatePrimaryDefault: "#252829",
  themeBackgroundAlternatePrimarySelected: "#485050",
  themeBackgroundAlternatePrimaryHover: "#697576",
  themeBackgroundAlternatePrimaryPressed: "#949C9C",
  themeBackgroundAlternateSecondaryDefault: "#0B0C0C",
  themeBackgroundAlternateTertiaryDefault: "#485050",
  themeBackgroundAlternateTertiaryFocus: "#252829",
  themeBackgroundAccentDefault: "#055D7B",
  themeBackgroundAccentDisabled: "#14181D",
  themeBackgroundInvertedDefault: "#FFFFFF",
  themeBackgroundOverlayDefault: "rgba(108, 119, 120, 0.5)",
  themeBackgroundOverlayPressed: "rgba(255, 255, 255, 0.16)",
  themePalette50: "#E5F6FB",
  themePalette100: "#B9E8F7",
  themePalette200: "#7CCEE9",
  themePalette300: "#47C2EC",
  themePalette400: "#00A8E1",
  themePalette500: "#077398",
  themePalette600: "#055D7B",
  themePalette700: "#03384B",
  themePalette800: "#01222D",
  themePaletteMuted100: "#C1DEE8",
  themePaletteMuted200: "#90C5D7",
  themePaletteMuted300: "#86A4AF",
  themePaletteMuted400: "#204B5A",
  themePaletteMuted500: "#24586A",
  themePaletteMuted600: "#14181D",
  _themeColorScaleCategorical: ["#36C2B4","#EB64D7","#FF8F00","#8169F8","#FCC201","#FF5A94","#708090"],
  _themeColorScaleComparison: ["#47B120","#EE5A46"],
  _themeColorScaleViolet: ["#3E36BB","#614FD9","#8169F8","#A588FF","#C9A8FF","#DDC8FF"],
  _themeColorScalePurple: ["#8C297D","#C54FB4","#EB64D7","#EC88DC","#ECA8E0","#E9C6E4"],
  _themeColorScalePink: ["#BA1750","#DC3C71","#FF5A94","#FF8CAF","#FFB5CA","#FFDBE5"],
  _themeColorScaleTangerine: ["#A45C0A","#D4780C","#FF8F00","#FFAE5D","#FFCB96","#FFE5CB"],
  _themeColorScaleGold: ["#BF8E00","#DAA500","#FCC201","#FFDB86","#FFE8B1","#FFF4D9"],
  _themeColorScaleTurquoise: ["#1B5F5B","#227D74","#2C9F93","#36C2B4","#78D9CF","#C3E9E7"],
  _themeColorScaleForest: ["#005C00","#348118","#47B120","#7EC55E","#ABD994","#D6ECC9"],
  _themeColorScaleCrimson: ["#AF1F18","#DE1B1B","#EE5A46","#F98771","#FFB09F","#FFD9CF"],
  _themeColorScaleSlate: ["#434C54","#596571","#708090","#929EAB","#B6BDC6","#DADEE2"],
  _themeColorScaleDiverging: ["#2C9F93","#36C2B4","#78D9CF","#C3E9E7","#FFF4D9","#FFE8B1","#FFDB86","#FCC201"],
  _themeColorScaleDivergingWithMiddle: ["#C54FB4","#EB64D7","#EC88DC","#ECA8E0","#FFF4D9","#78D9CF","#36C2B4","#2C9F93","#227D74"]
};