Object.defineProperty(module.exports, "__esModule", {
  value: true
})

module.exports.default = {
  themeForegroundPrimaryDefault: "#232F3E",
  themeForegroundPrimaryFocus: "#077398",
  themeForegroundPrimaryError: "#AD0A30",
  themeForegroundPrimaryDisabled: "#BBC0C1",
  themeForegroundSecondaryDefault: "#697576",
  themeForegroundSecondaryDisabled: "#E7E9E9",
  themeForegroundTertiaryDefault: "#697576",
  themeForegroundTertiaryHover: "#485050",
  themeForegroundTertiaryPressed: "#252829",
  themeForegroundTertiaryDisabled: "#ABB0B5",
  themeForegroundInvertedDefault: "#FFFFFF",
  themeForegroundAccentDefault: "#077398",
  themeForegroundAccentHover: "#055D7B",
  themeForegroundAccentPressed: "#03384B",
  themeForegroundAccentDisabled: "#C1DEE8",
  themeBorderPrimaryDefault: "#697576",
  themeBorderPrimaryDisabled: "#BBC0C1",
  themeBorderPrimaryFocus: "#077398",
  themeBorderPrimaryError: "#CC0C39",
  themeBorderPrimaryWarning: "#DCB700",
  themeBorderSecondaryDefault: "#BBC0C1",
  themeBorderSecondaryFocus: "#00A8E1",
  themeBorderTertiaryDefault: "#E7E9E9",
  themeBorderAlternateDefault: "rgba(0, 0, 0, 0)",
  themeBorderAccentDefault: "#00A8E1",
  themeBorderAccentHover: "#077398",
  themeBorderAccentPressed: "#055D7B",
  themeBorderAccentDisabled: "#90C5D7",
  themeBorderAccentPreselected: "#7CCEE9",
  themeBorderAlternateAccentDefault: "#055D7B",
  themeBackgroundPrimaryDefault: "#FFFFFF",
  themeBackgroundPrimaryDisabled: "#F0F1F2",
  themeBackgroundSecondaryDefault: "#F0F1F2",
  themeBackgroundSecondaryError: "#FF7494",
  themeBackgroundSecondaryWarning: "#FCDE4D",
  themeBackgroundTertiaryDefault: "#E7E9E9",
  themeBackgroundTertiaryHover: "#BBC0C1",
  themeBackgroundTertiaryPressed: "#949C9C",
  themeBackgroundTertiaryDisabled: "#ECEDED",
  themeBackgroundAlternatePrimaryDefault: "#FFFFFF",
  themeBackgroundAlternatePrimarySelected: "#F0F1F2",
  themeBackgroundAlternatePrimaryHover: "#E7E9E9",
  themeBackgroundAlternatePrimaryPressed: "#BBC0C1",
  themeBackgroundAlternateSecondaryDefault: "#F9FAFA",
  themeBackgroundAlternateTertiaryDefault: "#FFFFFF",
  themeBackgroundAlternateTertiaryFocus: "#F9FAFA",
  themeBackgroundAccentDefault: "#B9E8F7",
  themeBackgroundAccentDisabled: "#86A4AF",
  themeBackgroundInvertedDefault: "#0B0C0C",
  themeBackgroundOverlayDefault: "rgba(11, 12, 12, 0.5)",
  themeBackgroundOverlayPressed: "rgba(11, 12, 12, 0.04)",
  themePalette50: "#E5F6FB",
  themePalette100: "#B9E8F7",
  themePalette200: "#7CCEE9",
  themePalette300: "#47C2EC",
  themePalette400: "#00A8E1",
  themePalette500: "#077398",
  themePalette600: "#055D7B",
  themePalette700: "#03384B",
  themePalette800: "#01222D",
  themePaletteMuted100: "#C1DEE8",
  themePaletteMuted200: "#90C5D7",
  themePaletteMuted300: "#86A4AF",
  themePaletteMuted400: "#204B5A",
  themePaletteMuted500: "#24586A",
  themePaletteMuted600: "#14181D",
  _themeColorScaleCategorical: ["#36C2B4","#EB64D7","#FF8F00","#8169F8","#FCC201","#FF5A94","#708090"],
  _themeColorScaleComparison: ["#348118","#DE1B1B"],
  _themeColorScaleViolet: ["#DDC8FF","#C9A8FF","#A588FF","#8169F8","#614FD9","#3E36BB"],
  _themeColorScalePurple: ["#E9C6E4","#ECA8E0","#EC88DC","#EB64D7","#C54FB4","#8C297D"],
  _themeColorScalePink: ["#FFDBE5","#FFB5CA","#FF8CAF","#FF5A94","#DC3C71","#BA1750"],
  _themeColorScaleTangerine: ["#FFE5CB","#FFCB96","#FFAE5D","#FF8F00","#D4780C","#A45C0A"],
  _themeColorScaleGold: ["#FFF4D9","#FFE8B1","#FFDB86","#FCC201","#DAA500","#BF8E00"],
  _themeColorScaleTurquoise: ["#C3E9E7","#78D9CF","#36C2B4","#2C9F93","#227D74","#1B5F5B"],
  _themeColorScaleForest: ["#D6ECC9","#ABD994","#7EC55E","#47B120","#348118","#005C00"],
  _themeColorScaleCrimson: ["#FFD9CF","#FFB09F","#F98771","#EE5A46","#DE1B1B","#AF1F18"],
  _themeColorScaleSlate: ["#DADEE2","#B6BDC6","#929EAB","#708090","#596571","#434C54"],
  _themeColorScaleDiverging: ["#2C9F93","#36C2B4","#78D9CF","#C3E9E7","#FFF4D9","#FFE8B1","#FFDB86","#FCC201"],
  _themeColorScaleDivergingWithMiddle: ["#C54FB4","#EB64D7","#EC88DC","#ECA8E0","#FFF4D9","#78D9CF","#36C2B4","#2C9F93","#227D74"]
};