"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseSpacing = exports.parseAlignment = void 0;

var _spacing = _interopRequireDefault(require("@amzn/meridian-tokens/base/spacing"));

var _token = require("../_utils/token");

// Parses the value for a spacing prop. This turns a string of spacing names
// like "medium" or "small medium" into an array of spacing values like ["16px"]
// or ["8px", "16px"].
var parseSpacing = function parseSpacing(spacing) {
  return spacing ? spacing.split(" ").map(function (spacing) {
    return (0, _token.getToken)(_spacing["default"], "spacing", spacing) || 0;
  }) : [];
}; // Parses the value for a alignment prop. Given a valid alignment prop value
// this will return an array of two values representing x and y alignment
// respectively.


exports.parseSpacing = parseSpacing;

var parseAlignment = function parseAlignment(alignment) {
  var values = alignment.split(" ");
  return {
    x: values[1],
    y: values[0]
  };
};

exports.parseAlignment = parseAlignment;