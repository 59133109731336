"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _blueDark = _interopRequireDefault(require("@amzn/meridian-tokens/theme/blue-dark"));

// The pivotal token used to determine if a set of tokens are "dark" or "light"
var themeBackgroundPrimaryDefault = _blueDark["default"].themeBackgroundPrimaryDefault;
/**
 * Returns if a tokens object represents a "light" or "dark" color scheme
 */

var getColorSchemeFromTokens = function getColorSchemeFromTokens(tokens) {
  return tokens.themeBackgroundPrimaryDefault === themeBackgroundPrimaryDefault ? "dark" : "light";
};

var _default = getColorSchemeFromTokens;
exports["default"] = _default;