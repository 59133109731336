"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getRtl = exports.getHorizontalDirection = exports.getDOMDirection = void 0;

var _functional = require("./functional");

var _canUseDom = _interopRequireDefault(require("./can-use-dom"));

var rtlMap = {
  left: "right",
  right: "left",
  borderLeft: "borderRight",
  borderRight: "borderLeft",
  start: "right",
  end: "left",
  topLeft: "topRight",
  topRight: "topLeft",
  bottomLeft: "bottomRight",
  bottomRight: "bottomLeft"
};
var horizontalDirectionMap = {
  start: "left",
  end: "right"
};
/**
 * This method returns the RTL equivalent for an LTR direction.
 * Eg:
 *  getRtl("borderLeft") returns "borderRight"
 *  getRtl("start") returns "right"
 */

var getRtl = (0, _functional.memoize)(function (str) {
  return Object.prototype.hasOwnProperty.call(rtlMap, str) ? rtlMap[str] : str;
});
/**
 * This method returns left/right for start/end. This allows us to accept the
 * start/end prop for our components that have directional props (eg: alignmentHorizontal)
 * while still supporting IE11 which doesn't accept start/end.
 */

exports.getRtl = getRtl;
var getHorizontalDirection = (0, _functional.memoize)(function (str) {
  return Object.prototype.hasOwnProperty.call(horizontalDirectionMap, str) ? horizontalDirectionMap[str] : str;
});
/**
 * Returns the dir of the DOM from an anchor passed into it
 *
 * @param anchor
 */

exports.getHorizontalDirection = getHorizontalDirection;

var getDOMDirection = function getDOMDirection(anchor) {
  try {
    var dir = _canUseDom["default"] && anchor !== null && typeof anchor !== "undefined" ? window.getComputedStyle(anchor).direction : "ltr";
    return dir;
  } catch (_) {
    return "ltr";
  }
};

exports.getDOMDirection = getDOMDirection;